import React from 'react';
import { Layout, StaticSEO } from '@core';
import { FinnoscoreUploader } from 'src/components/composite/chart-generator/shared/components/base';

export default function () {
  return (
    <Layout>
      <StaticSEO pageId='finnoscoreUploader' />
      <FinnoscoreUploader />
    </Layout>
  );
}
